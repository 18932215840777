//variables

//priority
$priority_A: #cc324b;
$priority_B: #FF99B2;
$priority_C: #ffffff;
$white: white;
$gray: gray;
$red: #d9534f;
$black: black;
$orange: #fcb150;
$green: #11a8ab;
$background_color: #1f253d;
$background_card_color: #394264;
$input_background: #50597b;
$main_background: #1F253D;
$progress_bar_color: #cc324b;

//height of the element
$min-height: calc(100% - 56px);
